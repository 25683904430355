import React, { useEffect, useState } from 'react'
import Layout from '../../components/layouts/Layout'
import Chart from "react-apexcharts";
import { client2 } from '../../operationalRequest';


const Modbus = () => {
    const [data, setData] = useState<any[]>([]);
    const [options, setOptions] = useState({
        chart: {
            borderRadius: 100,
            responsive: true,
        },
        noData: {
            text: "No data available",
            align: "center",
            offsetX: 0,
            offsetY: 0,
            style: {
                fontSize: "14px",
            },
        },
        legend: {
            position: "top",
        },
        xaxis: {
            type: "category",
            title: {
                text: "Minutes",
            },
            categories: [], // Dynamically update this
        },
        yaxis: {
            title: {
                text: "Energy Meter(kW)",
            },
            labels: {
                formatter: (value: any) => value.toFixed(2), // Format y-axis values
            },
        },
        dataLabels: {
            enabled: false,
        },
        stroke: {
            curve: "smooth",
            width: [2, 2, 2, 2],
        },
    });

    const fetchData = async () => {
       
        try {
            const response = await client2.get(`api/modbus-integration`);
            // console.log("Response data:", response.data.data);

            const formattedData = response.data.data.map((series: any) => ({
                ...series,
                data: series.data.map((point: any) => ({
                    x: point.x, // Keep x as a string
                    y: point.y, // Keep y as a number
                })),
            }));

            setData(formattedData);

            // Dynamically update x-axis categories
            const categories =
                formattedData.length > 0
                    ? formattedData[0].data.map((point: any) => point.x)
                    : [];

            setOptions((prevOptions) => ({
                ...prevOptions,
                xaxis: {
                    ...prevOptions.xaxis,
                    categories: categories,
                    title: {
                        text: "Time",
                    },
                },
            }));
        } catch (error) {
            console.error("Error fetching chart data:", error);
            setData([]); // Handle error case
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    return (
            <Layout title="Simulator">
                <div className='h-96'>
                <Chart
                type={'line'}
                options={options as any}
                series={data}
                width="100%"
                height="100%"
            />
                </div>
            </Layout>
    );
}

export default Modbus